import { tradeRoutes } from "@/pages/trade-crypto/routes";
import { walletsRoute } from "@/pages/wallet/routes";
import { MIDDLEWARES, ROUTES } from "@/utils/consts";
import { RouteLocationNormalized } from "vue-router";

const maintenanceMode = import.meta.env.VITE_APP_MAINTENANCE_MODE as string;

const maintenanceRoutes = [
  {
    path: "/:pathMatch(.*)*",
    name: ROUTES.MAINTENANCE.name,
    component: () => import("@/pages/maintenance-mode.vue"),
  },
];

const routes = [
  ...(maintenanceMode === "true"
    ? maintenanceRoutes
    : [
      {
        path: ROUTES.LOGIN.path,
        name: ROUTES.LOGIN.name,
        component: () => import("@/pages/login.vue"),
      },
      {
        path: ROUTES.CREATE_ACCOUNT.path,
        name: ROUTES.CREATE_ACCOUNT.path,
        component: () => import("@/pages/create-account/index.vue"),
      },
      {
        path: ROUTES.CREATE_ACCOUNT.TELL_US_MORE.path,
        name: ROUTES.CREATE_ACCOUNT.TELL_US_MORE.name,
        component: () => import("@/pages/create-account/tell-us-more.vue"),
      },
      {
        path: ROUTES.RESET_PASSWORD.path,
        name: ROUTES.RESET_PASSWORD.name,
        component: () => import("@/pages/reset-password.vue"),
      },
      {
        path: ROUTES.INDEX.path,
        name: ROUTES.INDEX.name,
        component: () => import("@/authView"),
        children: [
          {
            path: "",
            name: ROUTES.INDEX.name,
            component: () => import("@/pages/index.vue"),
            meta: {
              middlewares: { [MIDDLEWARES.PROTECTED]: true },
              displayName: ROUTES.INDEX.displayName,
            },
            children: [
              // wallet deposit route
              {
                path: "deposit/",
                component: () => import("@/pages/wallet/actions/index.vue"),
                props: {
                  backPath: ROUTES.INDEX.path,
                },
                children: [
                  {
                    path: "",
                    component: () =>
                      import("@/pages/wallet/actions/select-category.vue"),
                    props: {
                      action: "deposit",
                      parentRoute: "",
                    },
                  },
                  {
                    path: ":category",
                    component: () =>
                      import(
                        "@/pages/wallet/actions/deposit/select-wallet-wrapper.vue"
                        ),
                    props: {
                      backPath: `/deposit/`,
                    },
                  },
                  {
                    path: ":category/:asset",
                    component: () =>
                      import(
                        "@/pages/wallet/actions/deposit/deposit-wrapper.vue"
                        ),
                    props: (route: any) => ({
                      backPath: `/deposit/${route.params.category}`,
                    }),
                  },
                ],
              },
              {
                path: "withdraw/",
                component: () => import("@/pages/wallet/actions/index.vue"),
                props: {
                  backPath: ROUTES.INDEX.path,
                },
                children: [
                  {
                    path: "",
                    component: () =>
                      import(
                        "@/pages/wallet/actions/withdraw/select-category.vue"
                        ),
                    props: {
                      action: "withdraw",
                      parentRoute: "",
                      backPath: "/",
                    },
                  },
                  {
                    path: ":category(crypto|fiat)",
                    component: () =>
                      import(
                        "@/pages/wallet/actions/withdraw/select-sub-category.vue"
                        ),
                    props: {
                      action: "withdraw",
                      parentRoute: "withdraw",
                      backPath: "/withdraw",
                    },
                  },
                  {
                    path: ":category(crypto)/:method(internal|external)",
                    component: () =>
                      import(
                        "@/pages/wallet/actions/withdraw/select-wallet-wrapper.vue"
                        ),
                    props: {
                      backPath: "/withdraw/crypto",
                    },
                  },
                  {
                    path: ":category(fiat)/:method(banks|momo|apex)",
                    component: () =>
                      import(
                        "@/pages/wallet/actions/withdraw/select-wallet-wrapper.vue"
                        ),
                    props: {
                      backPath: "/withdraw/fiat",
                    },
                  },
                  {
                    path: ":category(crypto)/:method(internal|external)/:asset",
                    component: () =>
                      import(
                        "@/pages/wallet/actions/withdraw/withdrawal-mode.vue"
                        ),
                    props: (route: RouteLocationNormalized) => ({
                      backPath: "/",
                      selectWalletPath: `/withdraw/${route.params.category}`,
                    }),
                  },
                  {
                    path: ":category(fiat)/:method(banks|momo|apex)/:asset",
                    component: () =>
                      import(
                        "@/pages/wallet/actions/withdraw/withdrawal-mode.vue"
                        ),
                    props: (route: RouteLocationNormalized) => ({
                      backPath: "/",
                      selectWalletPath: `/withdraw/${route.params.category}`,
                    }),
                  },
                ],
              },
              {
                path: "swap/:asset?",
                component: () => import("@/pages/wallet/actions/index.vue"),
                children: [
                  {
                    path: "",
                    component: () =>
                      import(
                        "@/pages/wallet/actions/trade/trade-wrapper.vue"
                        ),
                  },
                ],
              },
              {
                path: ROUTES.TRANSACTIONS.DETAILS.path,
                component: () => import("@/pages/transactions/show.vue"),
                meta: {
                  keepPosition: true,
                },
              },
              {
                path: ROUTES.BILLS.path,
                component: () => import("@/pages/payments/index.vue"),
                meta: {
                  middlewares: { [MIDDLEWARES.PROTECTED]: true },
                  displayName: ROUTES.BILLS.displayName,
                },
                props: {
                  backPath: ROUTES.INDEX.path,
                },
                children: [
                  {
                    path: "",
                    name: ROUTES.BILLS.name,
                    component: () =>
                      import("@/pages/payments/actions/index.vue"),
                    meta: {
                      middlewares: { [MIDDLEWARES.PROTECTED]: true },
                      displayName: ROUTES.BILLS.displayName,
                    },
                  },
                  {
                    path: ROUTES.BILLS.AIRTIME.path,
                    name: ROUTES.BILLS.AIRTIME.name,
                    component: () =>
                      import("@/pages/payments/actions/airtime/index.vue"),
                    meta: {
                      middlewares: { [MIDDLEWARES.PROTECTED]: true },
                      displayName: ROUTES.BILLS.AIRTIME.displayName,
                    },
                  },
                  {
                    path: ROUTES.BILLS.ELECTRIC_BILL.path,
                    name: ROUTES.BILLS.ELECTRIC_BILL.name,
                    component: () =>
                      import(
                        "@/pages/payments/actions/electric-bills/index.vue"
                        ),
                    meta: {
                      middlewares: { [MIDDLEWARES.PROTECTED]: true },
                      displayName: ROUTES.BILLS.ELECTRIC_BILL.displayName,
                    },
                  },
                  {
                    path: ROUTES.BILLS.CABLE_TV.path,
                    name: ROUTES.BILLS.CABLE_TV.name,
                    component: () =>
                      import("@/pages/payments/actions/cable-tv/index.vue"),
                    meta: {
                      middlewares: { [MIDDLEWARES.PROTECTED]: true },
                      displayName: ROUTES.BILLS.CABLE_TV.displayName,
                    },
                  },
                  {
                    path: ROUTES.BILLS.DATA_PLAN.path,
                    name: ROUTES.BILLS.DATA_PLAN.name,
                    component: () =>
                      import(
                        "@/pages/payments/actions/data-bundle/index.vue"
                        ),
                    meta: {
                      middlewares: { [MIDDLEWARES.PROTECTED]: true },
                      displayName: ROUTES.BILLS.DATA_PLAN.displayName,
                    },
                  },
                ],
              },
              {
                path: ROUTES.BET_TOP_UP.path,
                name: ROUTES.BET_TOP_UP.name,
                component: () =>
                  import("@/pages/payments/actions/bet-top-up/index.vue"),
                meta: {
                  middlewares: { [MIDDLEWARES.PROTECTED]: true },
                  displayName: ROUTES.BET_TOP_UP.displayName,
                },
              },
            ],
          },
          {
            path: ROUTES.GIFT_CARD_SELL.path,
            name: ROUTES.GIFT_CARD_SELL.name,
            component: () => import("@/pages/giftcards.vue"),
            meta: {
              middlewares: { [MIDDLEWARES.PROTECTED]: true },
              displayName: ROUTES.GIFT_CARD_SELL.displayName,
            },
            children: [
              {
                path: ROUTES.GIFT_CARD_SELL.path,
                name: ROUTES.GIFT_CARD_SELL.name,
                component: () => import("@/pages/giftcards/index.vue"),
                meta: {
                  middlewares: { [MIDDLEWARES.PROTECTED]: true },
                  displayName: ROUTES.GIFT_CARD_SELL.displayName,
                },
                children: [
                  {
                    path: ROUTES.GIFT_CARD_SELL.OFFER.path,
                    name: ROUTES.GIFT_CARD_SELL.OFFER.name,
                    component: () =>
                      import("@/pages/giftcards/giftcards-sell/index.vue"),
                    meta: {
                      middlewares: { [MIDDLEWARES.PROTECTED]: true },
                      displayName: ROUTES.GIFT_CARD_SELL.displayName,
                    },
                  },
                ],
              },
              {
                path: ROUTES.GIFT_CARD_BUY.path,
                name: ROUTES.GIFT_CARD_BUY.name,
                component: () => import("@/pages/giftcards/GiftcardBuy.vue"),
                meta: {
                  middlewares: { [MIDDLEWARES.PROTECTED]: true },
                  displayName: ROUTES.GIFT_CARD_BUY.displayName,
                },
                children: [
                  {
                    path: ROUTES.GIFT_CARD_BUY.OFFER.path,
                    name: ROUTES.GIFT_CARD_BUY.OFFER.name,
                    component: () =>
                      import("@/pages/giftcards/giftcards-buy/index.vue"),
                    meta: {
                      middlewares: { [MIDDLEWARES.PROTECTED]: true },
                      displayName: ROUTES.PROFILE.displayName,
                    },
                  },
                ],
              },
              {
                path: ROUTES.GIFT_CARD_TRADES.path,
                name: ROUTES.GIFT_CARD_TRADES.name,
                component: () =>
                  import("@/pages/giftcards/GiftcardTrades.vue"),
                meta: {
                  middlewares: { [MIDDLEWARES.PROTECTED]: true },
                  displayName: ROUTES.GIFT_CARD_TRADES.displayName,
                },
              },
              {
                path: ROUTES.GIFT_CARD_TRADE_CHAT.path,
                name: ROUTES.GIFT_CARD_TRADE_CHAT.name,
                sensitive: true,
                component: () =>
                  import("@/pages/giftcards/GiftcardTradeChat.vue"),
                meta: {
                  middlewares: { [MIDDLEWARES.PROTECTED]: true },
                  displayName: ROUTES.GIFT_CARD_TRADE_CHAT.displayName,
                },
              },
            ],
          },
          {
            path: ROUTES.TRANSACTIONS.path,
            name: ROUTES.TRANSACTIONS.name,
            component: () => import("@/pages/transactions/index.vue"),
            meta: {
              middlewares: { [MIDDLEWARES.PROTECTED]: true },
              displayName: ROUTES.TRANSACTIONS.displayName,
            },
            children: [
              {
                path: ROUTES.TRANSACTIONS.DETAILS.path,
                name: ROUTES.TRANSACTIONS.DETAILS.name,
                component: () => import("@/pages/transactions/show.vue"),
                meta: {
                  middlewares: { [MIDDLEWARES.PROTECTED]: true },
                  displayName: ROUTES.TRANSACTIONS.DETAILS.displayName,
                },
              },
              {
                path: ROUTES.TRANSACTIONS.SORT_BY.path,
                name: ROUTES.TRANSACTIONS.SORT_BY.name,
                component: () => import("@/pages/transactions/sort.vue"),
                meta: {
                  middlewares: { [MIDDLEWARES.PROTECTED]: true },
                  displayName: ROUTES.TRANSACTIONS.SORT_BY.displayName,
                },
              },
            ],
          },
          {
            path: ROUTES.PROFILE.ACCOUNT.path,
            name: ROUTES.PROFILE.ACCOUNT.name,
            component: () => import("@/pages/profile/AccountSettings.vue"),
            meta: {
              middlewares: { [MIDDLEWARES.PROTECTED]: true },
              displayName: ROUTES.PROFILE.ACCOUNT.displayName,
            },
            children: [
              {
                path: ROUTES.PROFILE.UPGRADE.path,
                name: ROUTES.PROFILE.UPGRADE.name,
                component: () => import("@/pages/profile/AccountUpgrade.vue"),
                meta: {
                  middlewares: { [MIDDLEWARES.PROTECTED]: true },
                  displayName: ROUTES.PROFILE.ACCOUNT.displayName,
                },
                props: {
                  backPath: "/profile",
                },
              },
              {
                path: ROUTES.PROFILE.SECURITY.path,
                name: ROUTES.PROFILE.SECURITY.name,
                component: () =>
                  import("@/pages/profile/LoginAndSecurity.vue"),
                meta: {
                  middlewares: { [MIDDLEWARES.PROTECTED]: true },
                  displayName: ROUTES.PROFILE.ACCOUNT.displayName,
                },
                props: {
                  backPath: "/profile",
                },
              },
            ],
          },
          {
            path: ROUTES.PROFILE.BUSINESS.path,
            name: ROUTES.PROFILE.BUSINESS.name,
            component: () =>
              import("@/pages/profile/BusinessIntegrationIndex.vue"),
            meta: {
              middlewares: { [MIDDLEWARES.PROTECTED]: true },
              displayName: ROUTES.PROFILE.BUSINESS.displayName,
            },
            props: {
              backPath: "/profile",
            },
            children: [
              {
                path: ROUTES.PROFILE.BUSINESS.path,
                name: ROUTES.PROFILE.BUSINESS.name,
                component: () =>
                  import("@/pages/profile/BusinessIntegration.vue"),
                meta: {
                  middlewares: { [MIDDLEWARES.PROTECTED]: true },
                  displayName: ROUTES.PROFILE.BUSINESS.displayName,
                },
                props: {
                  backPath: "/profile",
                },
              },
            ],
          },
          {
            path: ROUTES.PROFILE.path,
            name: ROUTES.PROFILE.name,
            component: () => import("@/pages/profile/index.vue"),
            meta: {
              middlewares: { [MIDDLEWARES.PROTECTED]: true },
              displayName: ROUTES.PROFILE.displayName,
            },
            children: [
              {
                path: "",
                name: ROUTES.PROFILE.INFORMATION.name,
                component: () =>
                  import("@/pages/profile/PersonalInformation.vue"),
                meta: {
                  middlewares: { [MIDDLEWARES.PROTECTED]: true },
                  displayName: ROUTES.PROFILE.INFORMATION.displayName,
                },
                props: {
                  backPath: "/profile",
                },
                children: [
                  {
                    path: ROUTES.PROFILE.USERNAME_UPDATE.path,
                    name: ROUTES.PROFILE.USERNAME_UPDATE.name,
                    component: () =>
                      import("@/pages/profile/UsernameUpdate.vue"),
                  },
                  {
                    path: ROUTES.PROFILE.EMAIL_UPDATE.path,
                    name: ROUTES.PROFILE.EMAIL_UPDATE.name,
                    component: () =>
                      import("@/pages/profile/updateIdentifier.vue"),
                  },
                ],
              },
            ],
          },
          ...tradeRoutes,
          ...walletsRoute,
          // ...billsRoute,
          {
            path: ROUTES.CARDS.path,
            name: ROUTES.CARDS.name,
            component: () => import("@/pages/cards/index.vue"),
            meta: {
              middlewares: { [MIDDLEWARES.PROTECTED]: true },
              displayName: ROUTES.CARDS.displayName,
            },
            children: [
              {
                path: ROUTES.CARDS.CREATE.path,
                name: ROUTES.CARDS.CREATE.name,
                component: () =>
                  import("@/pages/cards/create/CreateCardWrapper.vue"),
                meta: {
                  middlewares: { [MIDDLEWARES.PROTECTED]: true },
                  displayName: ROUTES.CARDS.CREATE.displayName,
                },
              },
              {
                path: ROUTES.CARDS.FUND.path,
                name: ROUTES.CARDS.FUND.name,
                component: () => import("@/pages/cards/fund/index.vue"),
                meta: {
                  middlewares: { [MIDDLEWARES.PROTECTED]: true },
                  displayName: ROUTES.CARDS.FUND.displayName,
                },
              },
              {
                path: ROUTES.CARDS.WITHDRAW.path,
                name: ROUTES.CARDS.WITHDRAW.name,
                component: () => import("@/pages/cards/withdraw/index.vue"),
                meta: {
                  middlewares: { [MIDDLEWARES.PROTECTED]: true },
                  displayName: ROUTES.CARDS.WITHDRAW.displayName,
                },
              },
              {
                path: ROUTES.CARDS.DETAILS.path,
                name: ROUTES.CARDS.DETAILS.name,
                component: () => import("@/pages/cards/show.vue"),
                meta: {
                  middlewares: { [MIDDLEWARES.PROTECTED]: true },
                  displayName: ROUTES.CARDS.DETAILS.displayName,
                },
              },
              {
                path: ROUTES.CARDS.MORE.path,
                name: ROUTES.CARDS.MORE.name,
                component: () => import("@/pages/cards/more/index.vue"),
                meta: {
                  middlewares: { [MIDDLEWARES.PROTECTED]: true },
                  displayName: ROUTES.CARDS.MORE.displayName,
                },
              },
              {
                path: ROUTES.CARDS.TRANSACTION.path,
                name: ROUTES.CARDS.TRANSACTION.name,
                component: () =>
                  import("@/pages/cards/transaction/index.vue"),
                meta: {
                  middlewares: { [MIDDLEWARES.PROTECTED]: true },
                  displayName: ROUTES.CARDS.TRANSACTION.displayName,
                },
              },
            ],
          },
          {
            path: ROUTES.REFERRALS.path,
            name: ROUTES.REFERRALS.name,
            component: () => import("@/pages/referrals/index.vue"),
            meta: {
              middlewares: { [MIDDLEWARES.PROTECTED]: true },
              displayName: ROUTES.REFERRALS.displayName,
            },
          },
        ],
      },
      {
        path: "/pd/c/:identifier_type/:token",
        name: "Verify Email",
        component: () => import("@/pages/confirm-change.vue"),
      },
      {
        name: "NotFound",
        path: "/:pathMatch(.*)*",
        component: () => import("@/pages/404.vue"),
      },
    ]),
];

export default routes;
